.listgroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 3px 0;
    color: #ffffff;
}
.listlabel{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
}
.listprice{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
}
.totalprice{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #FFFFFF;
    text-align: end;
    margin-top: 10px;
}